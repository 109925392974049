import { Component, OnInit } from '@angular/core';
import { DwMediaSelectorComponent } from '@devwareapps/devware-cap';

@Component({
  selector: 'dw-media-selector2',
  template: `
    <div>
      <div [ngClass]="selectorClass" (click)="selectFiles()"  dwDwFileDrop (fileDrop)="selectFiles($event)">
        <div id="media-selector-inner" [hidden]="!showSelectorText" *ngIf="!disabled" >
          <i [ngClass]="selectorIcon"></i><br />
          <span id="media-selector-text">{{selectorText}}</span>
        </div>
        <ng-content></ng-content>
      </div>
    <input type="file" #fileSingle placeholder="Choose file" (change)="selectFiles(fileSingle.files, fileSingle)" [accept]="allowedFileTypes" style="display:none;">
  </div>
  `
})
export class DwMediaSelector2Component extends DwMediaSelectorComponent {

  ngOnInit(): void {
  }

  selectFiles(event?: any, fileInput?: any): void {

    if (this.disabled) {
      return;
    }

    super.selectFiles(event, fileInput);
  }

}
 