import { Injectable } from "@angular/core";
import { AdminUserProperties } from "../models/admin-user-properties.model";
import { DwSecurityStateService, DwSecurityUserService, DwUser } from "@devwareapps/devware-cap";
import { AviatorUser } from "../../shared/models/aviator-user.model";
import { AppPermissions } from "../../../meta-data/app-permissions.enum";
import { Observable } from "rxjs";
import { SchoolRepositoryService } from "../../schools/services/school-repository.service";
import { tap } from "rxjs/operators";
import { FlightSchoolEntity } from "../../../meta-data/app-meta-data.service";
import { AviatorOnlineUserTypeIds } from "../../shared/models/aviator-user-type.enum";

@Injectable({providedIn: 'root'})
export class AdminManagerService {

    static isInitialized = false;

    static userProperties : AdminUserProperties;

    constructor(private dwSecurityUserService: DwSecurityUserService,
        private dwSecurityStateService: DwSecurityStateService,
        private schoolRepositoryService: SchoolRepositoryService
    ) {
        
    }


    getAviatorUser(user?: DwUser) : AviatorUser {
        if (!user) {
            user = this.dwSecurityUserService.securityState?.securityContext?.ApplicationUser;
        }
        return new AviatorUser(user, this.getAdminUserProperties(new AviatorUser(user, null)));
    }

    getAdminUserProperties(user: AviatorUser) : AdminUserProperties {

        let adminUserProperties : AdminUserProperties = AdminManagerService.userProperties;

        if (!AdminManagerService.isInitialized && user?.user?.UserName) {
            adminUserProperties = this.getLocalStorageProperties(user.user.UserName);

            AdminManagerService.userProperties = adminUserProperties;
            AdminManagerService.isInitialized = true;
        }

        if (!adminUserProperties) {
            adminUserProperties = {
                userTypeMode: this.getCurrentUserTypeByPermissions(), // user?.UserTypeId,
                flightSchoolId: user?.FlightSchoolId,
                flightSchoolName: user?.FlightSchoolName,
                flightSchoolBrandingThemeId: user?.FlightSchoolBrandingThemeId
            }
        }

        return adminUserProperties;
    }

    getCurrentUserTypeByPermissions(): number {
        let userTypeId = AviatorOnlineUserTypeIds.student;

        if (this.dwSecurityUserService.hasPermission(AppPermissions.flightSchoolAccess)) {
            userTypeId = AviatorOnlineUserTypeIds.flightSchool;
        }

        if (this.dwSecurityUserService.hasPermission(AppPermissions.flightSchoolAccess)) {
            userTypeId = AviatorOnlineUserTypeIds.flightSchool;
        }

        return userTypeId;
    }
    

    public setCurrentFlightSchool(flightSchoolId): Observable<any> {
        return this.schoolRepositoryService.getFlightSchool(flightSchoolId)
            .pipe(tap((flightSchool: FlightSchoolEntity) => {
                
                this.updateAdminProperties({
                    flightSchoolId: flightSchool.FlightSchoolId,
                    flightSchoolName: flightSchool.FlightSchoolName,
                    flightSchoolBrandingThemeId: flightSchool.FlightSchoolBranding?.ThemeId
                });

            }));
    }

    public updateAdminProperties(properties: Partial<AdminUserProperties>) {
        if (!this.dwSecurityUserService.hasPermission(AppPermissions.lMSSetupAdmin)) {
            return;
        }

        const existingProperties = this.getAdminUserProperties(new AviatorUser(this.dwSecurityUserService.securityState.securityContext.ApplicationUser, null));

        const updatedProperties = {
            ...existingProperties,
            ...properties
        }

        this.setLocalStorageProperties(this.dwSecurityUserService.securityState.securityContext.ApplicationUser?.UserName, updatedProperties);

        AdminManagerService.userProperties = updatedProperties;

        this.dwSecurityStateService.setSecurityContext({ ...this.dwSecurityUserService.securityState.securityContext, lastUpdated: new Date() } as any);
    }

    private getLocalStorageProperties(userName: string) : AdminUserProperties {
        const key = `AdminUserProperties-${userName}`;

        let properties = localStorage.getItem(key);

        if (properties) {
            try {
                return JSON.parse(properties);
            } catch (e) {
                console.error(e);
                return null;
            }
        }

        return null;
    }

    private setLocalStorageProperties(userName: string, properties: AdminUserProperties) {
        const key = `AdminUserProperties-${userName}`;

        localStorage.setItem(key, JSON.stringify(properties));
    }


}