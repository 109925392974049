<div class="card card-container card-item quiz-card-container" (click)="click()">
    <div class="card-image">
        <dw-image [src]="imageUrl" [defaultSrc]="defaultImageUrl" maxWidth2="250px" maxHeight2="141px"></dw-image>
    </div>
    <div class="card-body">
        <h5 class="card-title">{{data?.LessonName}}</h5>

        <div class="card-description">
            <!-- <dw-html-view [ngModel]="data?.Quiz?.QuizDescription"></dw-html-view> -->

            <div [hidden]="!passFailPercentText">Pass/Fail %: {{passFailPercentText}}</div>
            <div>Pass Percentage: {{quiz.PassingPercent}}%</div>
            <div [hidden]="!timeLimit">Time Allowed: {{timeLimit}}</div>
        </div>
    </div>
</div>