<div style="overflow:hidden">
    <div dwCol2="4" style="width:220px;float:left">
        <label class="label">Logo</label>
        <div style="width:220px;">

            <div style="width:220px">
                <div style="width:200px;float:left;">
                    <p-slider [(ngModel)]="theme.AlternateLogoWidth" (ngModelChange)="setImageSize()" [min]="50"
                        *ngIf="!isReadOnly" [max]="200"></p-slider>

                    <dw-media-selector2 [disabled]="isReadOnly" [media]="sideLogoMedia"
                        (mediaChange)="setSideLogoMedia($event)" selectorText="Select Logo..."
                        resizeOptions="logoMediaImageReSize" selectorClass2="aviator-media-select"
                        [showSelectorText]="false">
                        <div [ngStyle]="sideLogoStyle" style="margin-top:5px;width:200px;height:200px"></div>
                    </dw-media-selector2>
                </div>
                <div style="width:20px;float:left;padding-left:15px;margin-top:10px;">
                    <p-slider [(ngModel)]="theme.AlternateLogoHeight" (ngModelChange)="setImageSizeHeight()" [min]="50"
                        *ngIf="!isReadOnly" orientation="vertical" [max]="200"></p-slider>
                </div>
            </div>
        </div>
    </div>
    <div dwCol2="8" style="width:calc(100% - 240px);float:left;margin-left:20px;">
        <div dwRow>
            <div dwCol="6">
                <label class="label">Top Navigation</label>

                <dw-color-set-picker [(ngModel)]="flightSchool.FlightSchoolBranding.NavigationColorSetId"
                    [disabled]="isReadOnly" (ngModelChange)="updateColorSet()"></dw-color-set-picker>
            </div>
            <div dwCol="6">
                <label class="label">Side Bar</label>
                <!-- <dw-color-set-picker formControlName="SidebarColorSetId"
                (ngModelChange)="updateColorSet()"></dw-color-set-picker> -->
                <dw-color-set-picker [(ngModel)]="flightSchool.FlightSchoolBranding.SidebarColorSetId"
                    [disabled]="isReadOnly" (ngModelChange)="updateColorSet()"></dw-color-set-picker>
            </div>
        </div>
    </div>

</div>
<ng-container *ngIf="false">
    <div dwRow>
        <div dwCol="4">
            <label class="label">Logo Size</label>
            <p-slider [(ngModel)]="theme.AlternateLogoWidth" (ngModelChange)="themeChange()" [min]="50"
                [max]="200"></p-slider>
        </div>
        <div dwCol="6">
            <label class="label">Logo Width</label>
            <input type="text" dwNumeric [(ngModel)]="theme.AlternateLogoWidth" class="form-control" />
        </div>
        <div dwCol="6">
            <label class="label">Logo Height</label>
            <input type="text" dwNumeric [(ngModel)]="theme.AlternateLogoHeight" class="form-control" />
        </div>
    </div>
    <!--
<div dwRow>
    <div dwCol="6">
        <dw-theme-property-editor [themeProperyDefs]="themeProperyDefs" [(themeProperties)]="theme.DwThemeProperty" [propertyId]="DwThemeProperties.topNavigationBackgroundColor">
        </dw-theme-property-editor>
    </div>
    <div dwCol="6">
        <label class="label">Navigation Text Color</label>
        <dw-theme-property-editor [themeProperyDefs]="themeProperyDefs" [(themeProperties)]="theme.DwThemeProperty" [propertyId]="DwThemeProperties.topNavigationBackgroundColor">
        </dw-theme-property-editor>
    </div>
</div> -->

    <div dwRow>
        <div dwCol="6">
            <div [ngStyle]="sideLogoStyle"></div>
        </div>
        <div dwCol="6">
            <label class="label">Side Logo</label>
            <dw-media-item [media]="sideLogoMedia" [sizeOptions]="logoSizeOptions" mediaClass="aviator-side-logo"
                mediaClass2="dw-avatar-small dw-avatar-banner-position dw-avatar-image"></dw-media-item>

            <!-- <dw-media-selector selectorClass="dw-avatar-media-select"
                selectorText="Change Image..." [media]="sideLogoMedia" (mediaChange)="setSideLogoMedia($event)"
                [resizeOptions]="avatarMediaImageReSize">
                <dw-media-item [media]="sideLogoMedia" [sizeOptions]="logoSizeOptions"
                    mediaClass="aviator-side-logo" mediaClass2="dw-avatar-small dw-avatar-banner-position dw-avatar-image"></dw-media-item>
            </dw-media-selector> -->
        </div>
    </div>
    <div dwRow>
        <div dwCol="4">
            <label class="label">Top Navigation</label>
            <dw-color-set-picker [(ngModel)]="flightSchool.FlightSchoolBranding.NavigationColorSetId"
                (ngModelChange)="updateColorSet()"></dw-color-set-picker>
        </div>
        <div dwCol="4">
            <label class="label">Side Bar</label>
            <dw-color-set-picker [(ngModel)]="flightSchool.FlightSchoolBranding.SidebarColorSetId"
                (ngModelChange)="updateColorSet()"></dw-color-set-picker>
        </div>
        <div dwCol="4">
            <label class="label">Choose Manual Colors</label>
            <dw-yesno [(ngModel)]="flightSchool.FlightSchoolBranding.UseCustomColors"
                (ngModelChange)="updateColorSet()"></dw-yesno>
        </div>
    </div>
    <div dwRow *ngIf="flightSchool.FlightSchoolBranding.UseCustomColors">
        <div dwCol="3" *ngFor="let property of stylePropertyConfig">
            <dw-theme-property-editor [themeProperyDefs]="themeProperyDefs" [(themeProperties)]="theme.DwThemeProperty"
                (themePropertiesChange)="themeChange()" [propertyId]="property.propertyDef">
            </dw-theme-property-editor>
        </div>
    </div>

</ng-container>

<ng-container *ngIf="!isReadOnly">
    <label class="label">Auto Apply Changes</label> <dw-yesno [(ngModel)]="autoApplyTheme"
        (ngModelChange)="autoApplyThemeChange()"></dw-yesno>

    <!-- <button class="btn btn-warning" (click)="applyTheme()">Apply Theme</button>
    <button class="btn btn-warning" (click)="undoTheme()">Undo Theme</button> -->
</ng-container>
<!-- 

{{ theme | json }} -->