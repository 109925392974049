import { Component, OnInit } from '@angular/core';
import { DwComponent, DwComponentType } from '@devwareapps/devware-cap';
import { AdminManagerService } from '../../services/admin-manager.service';
import { AppMetaDataLookups, UserTypeAllItems } from '../../../../meta-data/app-meta-data.service';


@DwComponent({
  key: 'sc-set-user-type',
   name: 'Set User Type',
   isGlobal: true,
   componentType: DwComponentType.menuComponent
})
@Component({
  selector: 'app-set-user-type',
  template: `
    <div class="dropdown-item2" style="padding:10px" >
         Set User Type

           <dw-radio-lookup [(ngModel)]="adminUserTypeId" (ngModelChange)="onMenuClick($event)"
                [lookupId]="userTypeLookupId"></dw-radio-lookup>
    </div>
  `
})
export class SetUserTypeComponent implements OnInit {
  userTypeLookupId = AppMetaDataLookups.UserTypeAllItems;
  adminUserTypeId: any;

  constructor(private adminManagerService: AdminManagerService) { }

  ngOnInit(): void {

    const user = this.adminManagerService.getAviatorUser();

    this.adminUserTypeId = user.UserTypeId;
  }

  onMenuClick(event: any) {
    this.adminManagerService.updateAdminProperties({
      userTypeMode: this.adminUserTypeId // this.showAdminType ? UserTypeAllItems.Student : UserTypeAllItems.FlightInstructor
    });
  }

}
