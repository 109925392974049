import { Inject, Injectable } from "@angular/core";
import { DwDataItemKeyValues, DwDataPageResults, DwDataRequest, DwDatasource, DwItemType, DwMetaDataService, DwMetaDataServiceToken, DwOrmData, DwOrmDataHierarchy, DwOrmDataService, DwOrmDataServiceToken, DwOrmDataSource, DwQuery, DwSecurityUserService } from "@devwareapps/devware-cap";
import { AppMetaData, AppMetaDataItemNames } from "../../../meta-data/app-meta-data.service";
import { AppDevwareBootstrapService } from "src/app/app-devware-registrations.service";
import { Observable, of } from "rxjs";
import { AviatorUser } from "../../shared/models/aviator-user.model";
import { AdminManagerService } from "../../admin/services/admin-manager.service";

@DwDatasource({
    keyName: 'SchoolSettingsDataSource',
    display: 'School Settings Data Source',
    itemName: AppMetaDataItemNames.FlightSchool,
})
@Injectable()
export class SchoolSettingsDataSourceService extends DwOrmDataSource {

    constructor(
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) metaDataService: DwMetaDataService,
        private securityUserService: DwSecurityUserService,
        private adminManagerService: AdminManagerService
    ) {
        super(dwOrmDataService, metaDataService, AppMetaDataItemNames.Course);
    }

   
    initDataSource() {
        this.setupOrmDataHierarchyFromQuery(this.item, this.ormDataHierarchy);
    }

    private setupOrmDataHierarchyFromQuery(item : DwItemType | DwQuery, ormDataHierarchy? : DwOrmDataHierarchy[]) {
        const brandingHierarchy : DwOrmDataHierarchy = {
            itemName: AppMetaData.FlightSchoolBranding.ItemDetail.itemName,
            propertyName: AppMetaData.FlightSchool.Relations.FlightSchoolBranding.propertyName,
            children: [{
                itemName: AppMetaData.DwTheme.ItemDetail.itemName,
                propertyName: AppMetaData.FlightSchoolBranding.Relations.DwTheme.propertyName,
            }]
        };

        // Remove existing hierarchy
        const existingHierarchy = ormDataHierarchy.find(h => h.itemName === AppMetaData.FlightSchoolBranding.ItemDetail.itemName);

        if (existingHierarchy) {
            ormDataHierarchy.splice(ormDataHierarchy.indexOf(existingHierarchy), 1);
        }

        ormDataHierarchy.push(brandingHierarchy);
    }
    
    loadItemFromQuery(query: DwQuery, keyValues: DwDataItemKeyValues | number): Observable<any> {
        const updatedQuery = this.buildFinalQuery(query);

        return super.loadItemFromQuery(updatedQuery, keyValues);
    }
    

    private buildFinalQuery(query: DwQuery): DwQuery {
        const flightSchoolQuery = AppMetaData.FlightSchool.CreateQueryBuilder(query);

        const dwThemeQuery = AppMetaData.DwTheme.CreateQueryBuilder();

        dwThemeQuery.addPrefetch(r => r.DwThemeProperty);

        const dwPrivateBrandingQuery = AppMetaData.FlightSchoolBranding.CreateQueryBuilder();

        dwPrivateBrandingQuery.addPrefetch(r => r.DwTheme, dwThemeQuery.query);
        
        let brandingPrefetch = query.Prefetch.find(p => p.RelationName === AppMetaData.FlightSchool.Relations.FlightSchoolBranding.relationName);

        if (brandingPrefetch == null) {
             flightSchoolQuery.addPrefetch(r => r.FlightSchoolBranding);

             brandingPrefetch = query.Prefetch.find(p => p.RelationName === AppMetaData.FlightSchool.Relations.FlightSchoolBranding.relationName);
        }

        brandingPrefetch.Query = dwPrivateBrandingQuery.query;

        return flightSchoolQuery.query;
    }


}