import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppMetaData, DwThemePropertyDefinitionEntity, DwThemePropertyEntity, ThemePropertyTypeAllItems } from '../../../../meta-data/app-meta-data.service';
import { DwInputConverter, intConverter } from '@devwareapps/devware-cap';

@Component({
  selector: 'dw-theme-property-editor',
  template: `
    <div *ngIf="themePropertyDef && themeProperty">
      <label class="label" [hidden]="!showLabel">{{themePropertyDef?.PropertyDisplay}}</label>
      <ng-continner [ngSwitch]="themePropertyDef?.ThemePropertyTypeId">
            <div *ngSwitchCase="PropertyTypes.Color">
                <dw-color-picker  [(ngModel)]="themeProperty.PropertyValue" (ngModelChange)="themePropertyChange()"></dw-color-picker>
            </div>
            <div *ngSwitchCase="PropertyTypes.Text">
                <dw-input [(ngModel)]="themeProperty.PropertyValue" (ngModelChange)="themePropertyChange()" inputClass="form-control"></dw-input>
            </div>
            <div *ngSwitchDefault>
                <dw-input [(ngModel)]="themeProperty.PropertyValue" (ngModelChange)="themePropertyChange()" inputClass="form-control"></dw-input>
            </div> 
        </ng-continner>
      </div>
  `
})
export class DwThemePropertyEditorComponent implements OnInit, OnChanges{
  @DwInputConverter(intConverter)
  @Input() propertyId: number;
   
  @Input() themeProperties: DwThemePropertyEntity[] = [];
  @Input() themeProperyDefs: DwThemePropertyDefinitionEntity[] = [];

  @Output() themePropertiesChange = new EventEmitter<DwThemePropertyEntity[]>();
  
  @Input() themeProperty: DwThemePropertyEntity;
  themePropertyDef: DwThemePropertyDefinitionEntity;

  @Input() showLabel: boolean = true;
  
  readonly PropertyTypes = ThemePropertyTypeAllItems;

  constructor() { }

  ngOnInit(): void {
    this.setupProperty();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setupProperty();
  }

  themePropertyChange() {
    this.themePropertiesChange.emit(this.themeProperties);
  }

  private setupProperty() {
    this.themePropertyDef = this.themeProperyDefs?.find(p => p.ThemePropertyDefinitionId == this.propertyId);

    if (!this.themePropertyDef) {
      return;
    }

    this.themeProperty = this.themeProperties?.find(p => p.ThemePropertyDefinitionId == this.propertyId);

    if (!this.themeProperty) {
      this.themeProperty = AppMetaData.DwThemeProperty.CreateEntity();
      this.themeProperty.ThemePropertyDefinitionId = this.propertyId;

      this.themeProperties.push(this.themeProperty);
      this.themePropertiesChange.emit(this.themeProperties);
    }
  }
}
