import { Injectable } from '@angular/core';
import { DevwareMetaData, DwAppPermissionEntity, DwAppPermissionMetaData, DwContextDefinition, DwCustomContextBase, DwCustomContextDefinition, DwOrmDataService, DwQuery, DwQueryHelper, DwSecurityTopics, DwSecurityUserService } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames } from '../../../meta-data/app-meta-data.service';


export const CurrentSchoolContext = 'currentSchoolContext';
@DwCustomContextDefinition({
    keyName: CurrentSchoolContext,
    display: 'Current School Context',
    isGlobal: true,
})
@Injectable()
export class CurrentFlightSchoolContextService extends DwCustomContextBase {

    constructor(private dwSecurityUserService: DwSecurityUserService,
        ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {
        contextDef.buildQueryFunction = (context) => this.buildQuery(context);

        contextDef.item = AppMetaDataItemNames.UserDetail;
        //contextDef.metaDataQueryId = 1019;

        contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.UserDetail.ItemDetail.itemName));


    }

    buildQuery(resolvedContext: any): DwQuery {
        const query = AppMetaData.UserDetail.CreateQueryBuilder();

        if (!this.dwSecurityUserService.securityContext?.ApplicationUser) {
            return null;
        }
   
        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        
        const user = this.dwSecurityUserService.securityContext.ApplicationUser;
        
        query.addFilterAnd(filters => filters.Equal(f => f.UserId, user?.UserId || -1));

        throw new Error('Current School Context Service is deprecated. Please use Current User Context Service instead.');
        
        return query.query;
    }


}
