import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DwMenuService, DwMenuServiceState, DwSecurityUserService, DwAuthenticationType } from '@devwareapps/devware-cap';
import { environment } from 'src/environments/environment';
import { AviatorUser } from '../../features/shared/models/aviator-user.model';
import { DwThemeManagerService } from '@devwareapps/devware-cap/devware-admin/themes-admin/services/dw-theme-manger.service';
import { SchoolBrandingService } from '../../features/schools/services/school-branding.service';
import { AdminManagerService } from '../../features/admin/services/admin-manager.service';
import { SchoolSelectionModalActionService } from '../../features/admin/actions/school-select-modal-action.service';
import { AppPermissions } from '../../meta-data/app-permissions.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showLayout: boolean = true;

  showSlashScreen: boolean = true;
  environmentMessage: string;
  scrolled = false;

  courseSideBarClass = '';

  themeClasses: string;

  showSideLogo: boolean = true;

  currentUser: AviatorUser;
  flightSchoolName: string = 'Default';

  constructor(
    private router: Router,
    private menuService: DwMenuService,
    private dwSecurityUserService: DwSecurityUserService,
    private schoolBrandingService: SchoolBrandingService,
    private adminManagerService: AdminManagerService,
    private schoolSelectModalActionService: SchoolSelectionModalActionService,
  ) {
    this.environmentMessage = environment.environmentMessage;
  }

  ngOnInit(): void {

    setTimeout(() => {
      this.showSlashScreen = false;
    }, 200);

    const bodyClasses = document.body.classList;
    //console.log(bodyClasses);

    document.addEventListener("scroll", (ev) => {
      let document = ev.target as any;
      if (document.scrollingElement.scrollTop > 50) {
        if (!this.scrolled) {
          this.scrolled = true;
          this.setTheme(this.menuService.menuState);
        }
      }
      else {
        if (this.scrolled) {
          this.scrolled = false;
          this.setTheme(this.menuService.menuState);
        }
      }
    });

    this.dwSecurityUserService.securityContextChanged().subscribe(change => {
      this.setTheme(this.menuService.menuState);
    });

    this.menuService.menuStateObservable
      .subscribe(menuState => {
        this.setTheme(menuState);
      })

    this.setTheme(this.menuService.menuState);
  }

  changeSchool() {
    if (this.dwSecurityUserService.hasPermission(AppPermissions.lMSAllSchoolsAdmin)) {
      this.schoolSelectModalActionService.handleAction(null).subscribe(() => {
        //this.setTheme(this.menuService.menuState);
      });
    }
  }

  private annonymousSetupStarted = false;
  private setTheme(menuState: DwMenuServiceState) {
    this.currentUser = this.adminManagerService.getAviatorUser(this.dwSecurityUserService.securityState?.securityContext?.ApplicationUser);
    this.flightSchoolName = this.currentUser?.FlightSchoolName;

    console.log('Start branding setup');
    this.schoolBrandingService.setupSchoolBranding(this.currentUser)
      .subscribe((applyingBrandingChange) => {
        if (!applyingBrandingChange) {
          this.finalSetTheme(menuState);
          console.log('School branding setup complete', applyingBrandingChange);
        }
      });
  }

  private finalSetTheme(menuState: DwMenuServiceState) {
    console.log('Final Set Theme');
    const currentMenu = menuState.currentMenu;

    this.showSideLogo = !(menuState.currentMenu.url?.indexOf('my-courses/') >= 0);

    let themeClass = Themes.home;

    if (!currentMenu) {
      this.themeClasses = themeClass;

      if (this.scrolled) {
        this.themeClasses += ',scrolled';
      }

      return;
    }

    themeClass = Themes.main;

    if (currentMenu.url?.indexOf('admin') >= 0) {
      themeClass = Themes.admin;
    }

    if (currentMenu.data?.referenceKey == 'home') {
      themeClass = Themes.home;
    }

    this.themeClasses = themeClass;

    if (this.scrolled) {
      this.themeClasses += ',scrolled';
    }

    this.courseSideBarClass = '';
    if (currentMenu.url.indexOf('courses/view/') >= 0) {
      this.courseSideBarClass = 'course-side-bar-width';
      // this.themeClasses += ',course-side-bar-width';
    }

    if (!this.dwSecurityUserService.securityState.isAuthenticated) {

      if (this.dwSecurityUserService.securityState.authenticationType == DwAuthenticationType.logout) {
        setTimeout(() => {
          this.themeClasses += ',home-page-annonymous';
          console.log('theme Classes', this.themeClasses);
        }, 250);
      } else {
        this.themeClasses += ',home-page-annonymous';
      }
    }

    console.log('theme Classes', this.themeClasses);

    this.deselectText();
  }

  private deselectText() {
    try {
      if (window.getSelection) {
        window.getSelection().removeAllRanges();
      }
      else
        if ((document as any).selection) {
          (document as any).selection.empty();
        }
    }
    catch (e) {

    }
  }

  ngOnDestroy(): void {
    document.removeEventListener("scroll", (ev) => { });
  }
  

}


enum Themes {
  home = 'home-theme',
  main = 'main-theme',
  admin = 'admin-theme'
}
