
<h1 (click)="toggleChangeDashboard()">Welcome {{aviatorUser?.user?.FirstName || 'Future Pilot'}}!</h1>

<!-- <div class="dashboard-type" *ngIf="!showDashboardSelect" (click)="toggleChangeDashboard()">
    <h4>{{dashboardName}}</h4>
</div>
<div class="dashboard-type" *ngIf="showDashboardSelect">
    <div dwRow>
        <div dwCol="4">
            <label>Change Dashboard</label>
            <select class="form-control" [ngModel]="dashboardUserTypeId" (ngModelChange)="changeDashboardType($event)">
                <option value="1">Student Dashboard</option>
                <option value="4">Instructor Dashboard</option>
                <option value="2">School Dashboard</option>
            </select>
        </div>
    </div>
</div> -->
   
<ng-continner [ngSwitch]="dashboardUserTypeId">
    <div *ngSwitchCase="UserTypeAllItems.FlightSchool">
        <app-school-dashboard></app-school-dashboard>
    </div>
    <div *ngSwitchCase="UserTypeAllItems.FlightInstructor">
        <app-instructor-dashboard ></app-instructor-dashboard>
    </div>
    <div *ngSwitchDefault>
        <app-student-dashboard></app-student-dashboard> 
    </div> 
</ng-continner>
