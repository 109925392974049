import { DwUser } from "@devwareapps/devware-cap";
import { AviatorOnlineUserTypeIds } from "./aviator-user-type.enum";
import { AdminUserProperties } from "../../admin/models/admin-user-properties.model";

export class AviatorUser
{
    user: DwUser;

    UserTypeId: AviatorOnlineUserTypeIds;
    FlightSchoolId: number;
    FlightSchoolName: string;
    FlightSchoolBrandingThemeId: number;

    constructor(user: DwUser, adminUserProperties: AdminUserProperties) {
        this.setupUser(user, adminUserProperties);
    }

    private setupUser(user: DwUser, adminUserProperties: AdminUserProperties) {
        this.user = user;

        this.UserTypeId = adminUserProperties?.userTypeMode || this.getExtenedAttributeInt('UserTypeId');
        this.FlightSchoolId = adminUserProperties?.flightSchoolId || this.getExtenedAttributeInt('FlightSchoolId');
        this.FlightSchoolName = adminUserProperties?.flightSchoolName || this.getExtenedAttribute('FlightSchoolName');
        this.FlightSchoolBrandingThemeId = adminUserProperties?.flightSchoolBrandingThemeId || this.getExtenedAttributeInt('FlightSchoolBrandingThemeId');
    }

    private getExtenedAttribute(name: string): string {
        const userTypeAttr = this.user?.ExtendedAttributes?.find(a => a.AttrName==name);

        if (userTypeAttr) {
            return userTypeAttr.AttrValue
        }

        return null;
    }

    
    private getExtenedAttributeInt(name: string): number {
        const value = this.getExtenedAttribute(name);

        if (value) {
            return parseInt(value);
        }

        return null;
    }

}