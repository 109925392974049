export enum DwThemeProperties {
    logoWidth = 1,
    logoHeight = 2,
    bodyBackgroundColor = 3,
    sidebarBackgroundColor = 4,
    sidebarTextColor = 5,
    sidebarWidth = 6,
    bodyTextColor = 7,
    topNavigationBackgroundColor = 8,
    topNavigationTextColor = 9,
    topNavigationBarHeight = 10,
    logoUrl = 11,
    topNavigationToggleColor = 12,
    topNavigationToggleColorHover = 13,
    containerColor = 14,
    containerRadius = 15,
    formContainerBackgroundColor = 16,
    formTextColor = 17,
    containerInnerBackgroundColor = 18,
    formHeaderColor = 19,
    gridOddRowColor = 20,
    gridBackgroundColor = 21,
    gridDataColor = 22,
    gridHeaderTextColor = 23,
    gridHeaderBackgroundColor = 24,
    gridOddRowTextColor = 25,
    loginButtonBackground = 26,
}