import { Inject, Injectable } from '@angular/core';
import { DevwareMetaData, DwAppPermissionEntity, DwAppPermissionMetaData, DwAttributeType, DwCacheService, DwContextDefinition, DwControlType, DwCustomContextBase, DwCustomContextDefinition, DwMetaDataService, DwMetaDataServiceToken, DwOrmDataService, DwOrmDataServiceToken, DwQuery, DwQueryHelper, DwQueryMdAttribute, DwSecurityTopics, DwSecurityUserService } from '@devwareapps/devware-cap';
import { AppMetaData, AppMetaDataItemNames, FlightSchoolEntity } from '../../../meta-data/app-meta-data.service';
import { Observable, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';


export const MySchoolContext = 'mySchoolContext';
@DwCustomContextDefinition({
    keyName: MySchoolContext,
    display: 'My School Context',
    isGlobal: true,
})
@Injectable()
export class MyFlightSchoolContextService extends DwCustomContextBase {

    constructor(private dwSecurityUserService: DwSecurityUserService,
        private dwCacheService: DwCacheService,
        @Inject(DwOrmDataServiceToken) private dwOrmDataService: DwOrmDataService,
        @Inject(DwMetaDataServiceToken) private dwMetaDataService: DwMetaDataService,
        ) {
        super();
    }

    setupContextDefinition(contextDef: DwContextDefinition) {
        contextDef.buildQueryFunction = (context) => this.buildQuery(context);

        contextDef.item = AppMetaDataItemNames.UserDetail;
        //contextDef.metaDataQueryId = 1019;

        contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];

        contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.UserDetail.ItemDetail.itemName));
    }

    buildQuery(resolvedContext: any): DwQuery {
        const query = AppMetaData.UserDetail.CreateQueryBuilder();

        if (!this.dwSecurityUserService.securityContext?.ApplicationUser) {
            return null;
        }
   
        query.query.FieldSettings.LoadAllLookupDisplayFields = true;
        
        const user = this.dwSecurityUserService.securityContext.ApplicationUser;
        
        query.addFilterAnd(filters => filters.Equal(f => f.UserId, user?.UserId || -1));

        throw new Error('Current School Context Service is deprecated. Please use Current User Context Service instead.');
        return query.query;
    }

    // setupContextDefinition(contextDef: DwContextDefinition) {
    //     contextDef.item = AppMetaDataItemNames.FlightSchool;
    //     //contextDef.metaDataQueryId = AppMetaData.Queries.FlightSchoolForMySchoolContext.QueryMeaning
        
    //     contextDef.cacheInvalidationTopics = [DwSecurityTopics.SECURITY_CHANGED];
    //     contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.FlightSchool.ItemDetail.itemName));
    //     contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.FlightSchoolUser.ItemDetail.itemName));
    //     contextDef.cacheInvalidationTopics.push(DwOrmDataService.ORM_EVENT_TOPICS.ItemChangedTopic(AppMetaData.FlightSchoolInstructor.ItemDetail.itemName));

    //     contextDef.attributes = this.getContextAttribute();
    //     //contextDef.data = {};
    //     contextDef.noCache = true;
    //     contextDef.getCustomContextFunction = (context: any, contextData: any) => {
    //         return this.getFlightSchoolDetails(contextDef.cacheInvalidationTopics);
    //     }
    // }

    // private getContextAttribute(): DwQueryMdAttribute[] {
    //     const attributes: DwQueryMdAttribute[] = [];

    //     this.addProperty(attributes, 'FlightSchoolId', 'Flight School Id', DwAttributeType.Integer, DwControlType.NumericTextBox);
    //     this.addProperty(attributes, 'FlightSchoolName', 'Flight School Name');

    //     return attributes
    // }

    // getFlightSchoolDetails(cacheInvalidationTopics: string[]): Observable<FlightSchoolEntity> {
    //     return this.dwCacheService.get('mySchoolContext', this.loadFlightSchoolDetails(), null, cacheInvalidationTopics);
    // }

    // loadFlightSchoolDetails(): Observable<FlightSchoolEntity> {
    //     return AppMetaData.Queries.FlightSchoolForMySchoolContext.CreateQueryBuilder(this.dwMetaDataService)
    //         .pipe(mergeMap(query => {

    //             const user = this.dwSecurityUserService.securityContext.ApplicationUser;
    //             if (!user) {
    //                 return of(null);
    //             }

    //             query.addFilterAnd(f => f
    //                 .GroupOr(
    //                     g => g.Equal(a => a.StudentUserId, user.UserId),
    //                     g => g.Equal(a => a.InstructorUserId, user.UserId),
    //                     g => g.Equal(a => a.FlightSchoolUserUserId, user.UserId),
    //             ));

    //             return this.dwOrmDataService.executeQuerySingle(query)
    //                 .pipe(tap(flightSchool => {
    //                     console.log(`My Flight School for user ${user.UserId}: ${flightSchool?.FlightSchoolId} (${flightSchool?.FlightSchoolName})`);
    //                 }));
    //         }));
    // }

}
