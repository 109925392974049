import { Component, forwardRef, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DwOrmDataServiceToken, DwOrmDataService, DwControlBaseComponent } from '@devwareapps/devware-cap';
import { AppMetaData, StandardColorSetEntity } from '../../../../meta-data/app-meta-data.service';
import { SchoolRepositoryService } from '../../../schools/services/school-repository.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'dw-color-set-picker',
  templateUrl: './dw-color-set-picker.component.html',
  styleUrls: ['./dw-color-set-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DwColorSetPickerComponent),
    multi: true,
}]
})
export class DwColorSetPickerComponent extends DwControlBaseComponent implements OnChanges, OnInit {
  colorSets: StandardColorSetEntity[];
  currentColor: StandardColorSetEntity;
  constructor(private schoolRepositoryService: SchoolRepositoryService, ) {
    super();
   } 
  
  ngOnChanges(changes: SimpleChanges): void {
    
  }

  ngOnInit(): void {
    this.getColorSets();

    this.setupCurrentColor();
  }

  translateTo(value: any) {
    
    this.setupCurrentColor();

    return super.translateTo(value);
  }

  translateFrom(value: any) {
    this.setupCurrentColor(); 

    return super.translateFrom(value);
  }

  private setupCurrentColor() {
    this.currentColor = this.colorSets?.find(c => c.StandardColorSetId == this.value);
  }

  getColorSets() {

    this.schoolRepositoryService.getColorSets()
      .subscribe(colorSets => {
        this.colorSets = [{
          ColorSetName: 'Default',
        } as any,...colorSets];

        this.setupCurrentColor();
      });
  }

}
