export enum AppPermissions {
    assignmentCanEditAll = 'Assignment-Can_Edit_All',
    assignmentsCanCreate = 'Assignments-Can_Create',
    assignmentsCanCreateForSchool = 'Assignments-Can_Create_For_School',
    assignmentsCanEditInstructor = 'Assignments-Can_Edit-Instructor',
    assignmentsCanView = 'Assignments-Can_View',
    aviatorOnlineUserTypeMenusCanSeeAll = 'Aviator_Online-User_Type_Menus-Can_See_All',
    cAPBasicUser = 'CAP_Basic_User',
    cAPFeatureAreasCanCreate = 'CAP-Feature_Areas-Can_Create',
    cAPFeatureAreasCanPublish = 'CAP-Feature_Areas-Can_Publish',
    cAPFeatureAreasCanUpdate = 'CAP-Feature_Areas-Can_Update',
    contentAdmin = 'Content_Admin',
    entAdminMetaDataCanAdmin = 'Ent_Admin_MetaDataCanAdmin',
    entAdminOtherCanAccess = 'Ent_Admin_OtherCanAccess',
    entAdminSecurityCanAccess = 'Ent_Admin_SecurityCanAccess',
    entAdminSecurityCanAdminAdvanced = 'Ent_Admin_SecurityCanAdminAdvanced',
    entAdminSecurityCanAdminUsers = 'Ent_Admin_SecurityCanAdminUsers',
    entAdminCanAccess = 'Ent_AdminCanAccess',
    entSecurityCanEmulate = 'Ent_Security_CanEmulate',
    entSecurityCanLogin = 'Ent_Security_CanLogin',
    entUiCanConfigure = 'Ent_Ui_CanConfigure',
    entUiContentCanEdit = 'Ent_Ui_Content_CanEdit',
    entWorkItemTrackingCanAccess = 'Ent_WorkItemTracking_CanAccess',
    entWorkItemTrackingCanAdmin = 'Ent_WorkItemTracking_CanAdmin',
    flightSchoolAccess = 'Flight_School_Access',
    flightSchoolAdmin = 'Flight_School_Admin',
    flightSchoolCanManagerAllUsers = 'Flight_School-Can_Manager_All_Users',
    flightSchoolCanViewAllUsers = 'Flight_School-Can_View_All_Users',
    instructorAccess = 'Instructor_Access',
    instructorsCanManageForSchool = 'Instructors-Can_Manage_for_School',
    joinRequestsCanSend = 'Join_Requests-Can_Send',
    joinRequestsCanView = 'Join_Requests-Can_View',
    lMSAdminMenuAccess = 'LMS_Admin_Menu_Access',
    lMSSetupAdmin = 'LMS_Setup_Admin',
    lMSAllSchoolsAdmin = 'LMS-All_Schools_Admin',
    lMSNoSessionLogout = 'LMS-No_Session_Logout',
    studentAccess = 'Student_Access',
    studentCourseCanAssign = 'Student_Course-Can_Assign',
    studentCourseCanDelete = 'Student_Course-Can_Delete',
    studentCourseCanResetProgress = 'Student_Course-Can_Reset_Progress',
    studentGroupsCanManage = 'Student_Groups-Can_Manage',
    studentGroupsCanView = 'Student_Groups-Can_View',
    studentCanManageAll = 'Student-Can_Manage_All',
    studentCanManageForSchool = 'Student-Can_Manage_for_School',
    studentsCanManageInstructorAssigned = 'Students-Can_Manage_Instructor_Assigned',
    userAdmin = 'User_Admin',
    videoCanSeek = 'Video-Can_Seek',
}
