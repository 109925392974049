import { Injectable, Optional } from "@angular/core";
import { DwActionHandler, DwModalService, DwMenuService, DwMetaDataGridActionState, DwActionResult, DwAction, DwActionType, DwModalConfig, DwImportMetaDataModalComponent, DwModalButtonStandardConfigs, DwGridActionResult, DwRoutingService, DwModalSize, ensureArray, DwMetaDataFormStateService, DwMetaDataFormApi, DwEventService, DwGridEvents, DwActionState } from "@devwareapps/devware-cap";
import { Observable, of } from "rxjs";
import { AppMetaData, AppMetaDataItemNames, AssignmentStudentEntity } from "../../../meta-data/app-meta-data.service";
import { map } from "rxjs/operators";
import { CourseProgressVariableContext } from "../../courses/models/course-progress-variable-context.model";
import { AppPermissions } from "../../../meta-data/app-permissions.enum";
import { SchoolSelectionModalComponent } from "../components/school-selection-modal/school-selection-modal.component";

@DwAction({
    keyName: 'school-select-modal-action',
    display: 'School Select Modal',
    actionType: DwActionType.menuAction,
    isGlobal: true,
    defaultSecurityConfig: {
        requireAuthentication: true,
        useEmulatedPermissions: false,
        permissionMeaning: AppPermissions.lMSSetupAdmin,
    }
})
@Injectable()
export class SchoolSelectionModalActionService implements DwActionHandler {
    constructor(
        private dwModalService: DwModalService,
        private dwMenuService: DwMenuService,
        private dwRoutingService: DwRoutingService,
        @Optional() private dwFormStateService: DwMetaDataFormStateService,
        private dwEventService: DwEventService
    ) {
    }

    handleAction(actionState: DwActionState<any>): Observable<DwActionResult> {
        return this.showModal(actionState);
    }

    private showModal(actionState: DwActionState<any>): Observable<DwActionResult> {
        let modalConfig = new DwModalConfig();

        modalConfig.buttons = DwModalButtonStandardConfigs.instance.okAndClose;

        modalConfig.allowFullScreen = true;
        modalConfig.modalSize = DwModalSize.medium;

        modalConfig.component = SchoolSelectionModalComponent;

        modalConfig.title = 'Change School';

        return this.dwModalService.showModal<any,any>(modalConfig)
            .pipe(map(modalResult => {
                const result = new DwActionResult();
                
                if (result.cancelled) {
                    result.cancelled = true;

                    return result;
                }

                result.success = true;
                result.message = 'School changed successfully';
                
                return result;
            }));
    }
}