import { Injectable } from "@angular/core";
import { DwAppInitService, DwThemeManagerService } from "@devwareapps/devware-cap";
import { AviatorUser } from "../../shared/models/aviator-user.model";
import { map, mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SchoolBrandingService {

    private currentThemeId: any;
    private changingTheme:boolean = false;

    constructor(private themeManagerService: DwThemeManagerService,
        private appInitService: DwAppInitService) {
            
        }

    setupSchoolBranding(user: AviatorUser): Observable<boolean> {

        return this.appInitService.getAppInitResult()
            .pipe(mergeMap(result => {
                let newThemeId = result?.ThemesConfig?.CurrentThemeId;

                if (user.FlightSchoolBrandingThemeId) {
                    newThemeId = user.FlightSchoolBrandingThemeId;
                }

                if (!newThemeId || newThemeId == this.currentThemeId) { 

                    console.log('No theme change required');
                    return of(this.changingTheme);
                }

                console.log(`Changing Theme from ${this.currentThemeId} to ${newThemeId}`);

                this.currentThemeId = newThemeId;

                this.changingTheme = true;

                return this.themeManagerService.applyTheme({
                    ThemeId: newThemeId,
                } as any)
                    .pipe(map(() => {
                        this.changingTheme = false;
                        console.log(`Chaing Theme COMPLETE from ${this.currentThemeId} to ${newThemeId}`);

                        return this.changingTheme;
                    }));
            }));
    }

}