<p-dropdown *ngIf="colorSets && !disabled" [placeholder]="placeholder" [options]="colorSets" optionLabel="ColorSetName" display="chip" optionValue="StandardColorSetId" [(ngModel)]="translatedValue" [showClear]="true" (ngModelChange)="setTranslatedValue($event)" [style]="{'width':'100%'}" [filter]="showFilter" scrollHeight="300px" appendTo="body">
    <ng-template pTemplate="selectedItem" let-color>
        <div class="color-patch" [ngStyle]="{ 'background-color': color?.BackgroundColor, 'color': color?.ForegroundColor }">{{ color?.ColorSetName }}</div> 
    </ng-template>
    <ng-template let-color pTemplate="item">
        <div class="color-patch" [ngStyle]="{ 'background-color': color?.BackgroundColor, 'color': color?.ForegroundColor }"> {{ color?.ColorSetName }}</div>
    </ng-template>
</p-dropdown>

<div class="color-patch" *ngIf="disabled && currentColor" [ngStyle]="{ 'background-color': currentColor?.BackgroundColor, 'color': currentColor?.ForegroundColor }"> {{ currentColor?.ColorSetName }}</div>
<div class="color-patch" *ngIf="disabled && !currentColor">Default</div>

